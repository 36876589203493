import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { TournamentDetails } from "../TimeLine/TimeLine";

import "./Map.scss";

type MapProps = {
  focusedTournaments: {
    tournament: TournamentDetails;
    htmlItem: HTMLDivElement;
  }[];
};

// const focusedTournaments = [
//   {
//     tournament: {
//       id: "300",
//       date: "11/2/2023",
//       title: "Megacity",
//       coordinate: { x: 4026 / 2, y: 0 },
//     },
//     htmlItem: null,
//   },
// ];

export const Map = ({ focusedTournaments }: MapProps) => {
  const [mapImage, setMapImage] = useState<HTMLImageElement>();
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const requestRef = React.useRef<number>();
  const currentFactor = React.useRef<number>(0);

  const animate = useCallback(() => {
    const context = canvasRef.current?.getContext("2d");
    if (context && mapImage) {
      context.clearRect(0, 0, window.innerWidth, window.innerHeight);
      //  scale the image to fit the window width
      const mostRightTournament = focusedTournaments.reduce((a, b) => {
        return Math.max(b.tournament.coordinate.x, a);
      }, 0);

      const newWidth = (window.innerWidth - 500) / mostRightTournament; //- mostRightTournament; // - window.innerWidth * pct; // - mostRightTournament; // * pct;
      const factor = newWidth; //h / window.innerHeight; // mapImage.height;
      if (!currentFactor.current) {
        currentFactor.current = factor;
      } else {
        currentFactor.current += (factor - currentFactor.current) * 0.01;
      }

      context.drawImage(
        mapImage,
        0,
        0,
        mapImage.width * currentFactor.current,
        mapImage.height * currentFactor.current
      );

      context.fillStyle = "red";
      focusedTournaments.forEach(({ tournament, htmlItem }) => {
        const { coordinate } = tournament;
        const rect = htmlItem.getBoundingClientRect();
        context.fillRect(
          coordinate.x * currentFactor.current,
          coordinate.y * currentFactor.current,
          10,
          10
        );
        context.beginPath(); // Start a new path
        // context.moveTo(window.innerWidth - 400, 400);
        context.moveTo(window.innerWidth - 400, rect.y + 12);
        context.lineTo(
          coordinate.x * currentFactor.current,
          coordinate.y * currentFactor.current
        );
        context.stroke();
      });
    }

    requestRef.current = requestAnimationFrame(animate);
  }, [mapImage, focusedTournaments]);

  useEffect(() => {
    const img = new Image();
    const onLoaded = () => {
      setMapImage(img);
    };
    img.addEventListener("load", onLoaded);
    img.src = "map.png";

    return () => img.removeEventListener("load", onLoaded);
  }, []);

  useEffect(() => {
    const resize = () => {
      if (!canvasRef.current) return;
      canvasRef.current.width = window.innerWidth;
      canvasRef.current.height = window.innerHeight;
    };
    window.addEventListener("resize", resize, false);

    resize();

    return () => window.removeEventListener("resize", resize);
  }, [canvasRef]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      requestRef.current && cancelAnimationFrame(requestRef.current);
    };
  }, [animate]); // Make sure the effect runs only once

  return <canvas ref={canvasRef} className="map" />;
};
