import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";

import "./TimeLine.scss";

gsap.registerPlugin(Draggable, InertiaPlugin);

export type TournamentDetails = {
  date: string;
  title: string;
  id: string;
  coordinate: { x: number; y: number };
};
type ModelProps = {
  onTournamentsFocused: (
    t: { tournament: TournamentDetails; htmlItem: HTMLDivElement }[]
  ) => void;
  tournaments: TournamentDetails[];
};

export const TimeLine = ({ onTournamentsFocused, tournaments }: ModelProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const selectionRef = React.useRef<HTMLDivElement>(null);
  const tournamentsContainerRef = React.useRef<HTMLDivElement>(null);
  const requestRef = React.useRef<number>();

  const animate = useCallback(() => {
    requestRef.current = requestAnimationFrame(animate);
  }, [requestRef]);

  const onUpdate = useCallback(() => {
    const items: HTMLDivElement[] = gsap.utils.toArray(".timeline__item");

    const selectedIds: { id: string; item: HTMLDivElement }[] = items
      .filter((item: any) => {
        //get closest to center
        return Draggable.hitTest(item, selectionRef.current);
      })
      .map((item) => {
        return { id: item.dataset.id || "", item };
      });

    const tournamentAndItems = selectedIds.map((s) => {
      const tournament = tournaments.filter((t) => t.id === s.id)[0];
      const htmlItem = s.item;

      return { tournament, htmlItem };
    });

    onTournamentsFocused(tournamentAndItems);
  }, [onTournamentsFocused, tournaments]);

  useEffect(() => {
    Draggable.create(tournamentsContainerRef.current, {
      type: "y",
      edgeResistance: 0.95,
      bounds: containerRef.current,
      inertia: true,
      onThrowUpdate: () => {
        onUpdate();
      },
      onDrag: () => {
        onUpdate();
      },
      // snap: (val) => {
      //   //steps of 50
      //   return Math.round(val / 50) * 50;
      // },
    });

    onUpdate();
  }, [onUpdate]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      requestRef.current && cancelAnimationFrame(requestRef.current);
    };
  }, [animate]); // Make sure the effect runs only once

  return (
    <>
      <div className="selection-area" ref={selectionRef}></div>
      <div className="timeline" ref={containerRef}>
        <div ref={tournamentsContainerRef}>
          <div className="timeline__items">
            {tournaments.map((tournament) => {
              return (
                <div
                  key={tournament.id}
                  data-id={tournament.id}
                  className="timeline__item"
                >
                  {tournament.title}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
