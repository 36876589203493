// @ts-ignore

import { useCallback, useState } from "react";
import "./App.scss";
import { Map } from "./components/Map/Map";
import { TimeLine, TournamentDetails } from "./components/TimeLine/TimeLine";

const tournaments: TournamentDetails[] = [
  {
    id: "199760",
    date: "1/2/2023",
    title: "Far Hope",
    coordinate: { x: 770, y: 305 },
  },
  {
    id: "200",
    date: "11/2/2023",
    title: "Nomad Peaks",
    coordinate: { x: 629, y: 1102 },
  },
  {
    id: "200",
    date: "11/2/2023",
    title: "Hidden Gem",
    coordinate: { x: 629, y: 800 },
  },
  {
    id: "300",
    date: "11/2/2023",
    title: "Megacity",
    coordinate: { x: 2020, y: 1180 },
  },
  {
    id: "400",
    date: "13/2/2023",
    title: "Devil Peak",
    coordinate: { x: 3346, y: 1666 },
  },
  {
    id: "500",
    date: "13/2/2023",
    title: "Hona'pa reefs",
    coordinate: { x: 2422, y: 2695 },
  },
  {
    id: "600",
    date: "13/2/2023",
    title: "Fjalhem",
    coordinate: { x: 2115, y: 333 },
  },
  {
    id: "700",
    date: "15/2/2023",
    title: "Grezen Dul",
    coordinate: { x: 3510, y: 1099 },
  },
  {
    id: "800",
    date: "15/2/2023",
    title: "Korth Mordan",
    coordinate: { x: 3095, y: 994 },
  },
];
export const Site = () => {
  const [focusedTournaments, setFocusedTournaments] = useState<
    { tournament: TournamentDetails; htmlItem: HTMLDivElement }[]
  >([]);

  const onTournamentsSelected = useCallback(
    (t: { tournament: TournamentDetails; htmlItem: HTMLDivElement }[]) => {
      console.log("🚀 ~ t", t);
      setFocusedTournaments(t);
    },
    []
  );
  return (
    <div>
      <div className="page-container">
        <div className="page-container__map">
          <Map focusedTournaments={focusedTournaments} />
        </div>
        <div className="page-container__timeline">
          <TimeLine
            onTournamentsFocused={onTournamentsSelected}
            tournaments={tournaments}
          />
        </div>
      </div>
    </div>
  );
};
